import axios from 'axios'
import store from '@/store'

class UploadProvider {
  upload = (payload) => axios.post('https://api-rescue-station.duskyhub.com/uploads', payload, {
      headers: {
        'Authorization': `Bearer ${store.getters['auth/user'].accessToken}`,
        'Content-Type': 'multipart/form-data'
      }
    })
}

export default new UploadProvider()
