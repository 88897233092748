<template>
  <div class="member-update">
    <div class="member-update__container mb-6">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation>
        <h1 class="mb-4">
          {{ $t('app.page.memberUpdate.title') }}
        </h1>
        <v-row no-gutters>
          <v-col
            cols="12"
            md="4"
            class="pr-md-4">
            <div class="mb-2">
              {{ $t('app.label.firstName') }}
            </div>
            <v-text-field
              v-model="form.firstName"
              :rules="firstNameRules"
              :placeholder="$t('app.placeholder.firstName')"
              dense
              outlined
              required />
          </v-col>
          <v-col
            cols="12"
            md="4">
            <div class="mb-2">
              {{ $t('app.label.lastName') }}
            </div>
            <v-text-field
              v-model="form.lastName"
              :rules="lastNameRules"
              :placeholder="$t('app.placeholder.lastName')"
              dense
              outlined
              required />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            cols="12"
            md="4"
            class="pr-md-4">
            <div class="mb-2">
              {{ $t('app.label.telephone') }}
            </div>
            <v-text-field
              v-model="form.phoneNo"
              :rules="phoneNoRules"
              :onkeypress="onkeypress"
              :placeholder="$t('app.placeholder.telephone')"
              type="tel"
              maxlength="10"
              dense
              outlined
              required />
          </v-col>
          <v-col
            cols="12"
            md="4"
            class="pr-md-4">
            <div class="mb-2">
              {{ $t('app.label.email') }}
            </div>
            <v-text-field
              v-model="form.email"
              :rules="emailRules"
              :placeholder="$t('app.placeholder.email')"
              dense
              outlined
              required />
          </v-col>
          <v-col
            cols="12"
            md="4">
            <div class="mb-2">
              {{ $t('app.label.nationalId') }}
            </div>
            <v-text-field
              v-model="form.nationalId"
              :rules="nationalIdRules"
              :onkeypress="onkeypress"
              :placeholder="$t('app.placeholder.nationalId')"
              type="tel"
              maxlength="13"
              dense
              outlined
              required />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            cols="12"
            md="4"
            class="pr-md-4">
            <div class="mb-2">
              {{ $t('app.label.emergencyName') }}
            </div>
            <v-text-field
              v-if="form.emergencyContacts"
              v-model="form.emergencyContacts[0].name"
              :rules="emergencyNameRules"
              :placeholder="$t('app.placeholder.emergencyName')"
              dense
              outlined
              required />
          </v-col>
          <v-col
            cols="12"
            md="4">
            <div class="mb-2">
              {{ $t('app.label.emergencyTelephone') }}
            </div>
            <v-text-field
              v-if="form.emergencyContacts"
              v-model="form.emergencyContacts[0].phoneNo"
              :rules="emergencyPhoneNoRules"
              :onkeypress="onkeypress"
              :placeholder="$t('app.placeholder.emergencyTelephone')"
              maxlength="10"
              dense
              outlined
              required />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="3">
            <v-img
              :src="form.profilePictureUrl || 'https://via.placeholder.com/150x150'"
              height="150" />
          </v-col>
          <v-col
            cols="12"
            md="4">
            <v-file-input
              v-model="file"
              :rules="imageRules"
              :placeholder="$t('app.placeholder.image')"
              prepend-icon="mdi-upload"
              class="mt-md-12"
              dense
              outlined />
          </v-col>
        </v-row>
      </v-form>
    </div>
    <div class="text-right">
      <v-btn
        class="mr-4"
        text
        depressed
        @click="cancel()">
        {{ $t('app.btn.cancel') }}
      </v-btn>
      <v-btn
        :loading="isSubmitting"
        color="primary"
        depressed
        @click="submit()">
        {{ $t('app.btn.save') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import MemberProvider from '@/resources/axios/providers/member'
import UploadProvider from '@/resources/axios/providers/upload'

export default {
  name: 'MemberUpdate',
  data () {
    return {
      valid: true,
      isSubmitting: false,
      form: {
        id: null,
        profilePictureUrl: ''
      },
      file: null,
      onkeypress: 'return event.charCode >= 48 && event.charCode <= 57',
      firstNameRules: [(v) => !!v || this.$t('app.validate.firstName')],
      lastNameRules: [(v) => !!v || this.$t('app.validate.lastName')],
      phoneNoRules: [(v) => !!v || this.$t('app.validate.telephone')],
      emailRules: [(v) => !!v || this.$t('app.validate.email')],
      nationalIdRules: [(v) => !!v || this.$t('app.validate.nationalId')],
      emergencyNameRules: [(v) => !!v || this.$t('app.validate.emergencyName')],
      emergencyPhoneNoRules: [(v) => !!v || this.$t('app.validate.emergencyTelephone')],
      imageRules: [(v) => (!!this.form.profilePictureUrl || !!v) || 'กรุณาอัปโหลดรูปภาพ']
    }
  },
  mounted () {
    this.getMemberById()
  },
  methods: {
    ...mapActions({
      showErrorMessage: 'notification/showErrorMessage',
      closeErrorMessage: 'notification/closeErrorMessage'
    }),
    async getMemberById () {
      try {
        const data = await MemberProvider.getMemberOne(this.$route.params.memberId)
        if (data) {
          this.form = this.$_.cloneDeep(data)
        }
      } catch (error) {
        console.error(error)
      }
    },
    cancel () {
      this.$router.push({ name: 'Member' })
    },
    async submit () {
      if (this.isSubmitting) { return }
      const valid = this.$refs.form.validate()
      if (valid) {
        this.isSubmitting = true
        let url = ''
        if (this.file) {
          const formData = new FormData()
          formData.append('file', this.file)
          try {
            const { data } = await UploadProvider.upload(formData)
            if (data) {
              url = data.url
            }
          } catch (error) {
            console.error(error)
          }
        }
        const res = await MemberProvider.updateMember(this.$route.params?.memberId, {
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          phoneNo: this.form.phoneNo,
          email: this.form.email,
          nationalId: this.form.nationalId,
          profilePictureUrl: url || this.form.profilePictureUrl,
          status: this.form.status,
          emergencyContact: {
            name: this.form.emergencyContacts[0]?.name,
            phoneNo: this.form.emergencyContacts[0]?.phoneNo
          }
        })
        if (res) {
          this.showErrorMessage({
            message: 'แก้ไขข้อมูลเสร็จสิ้น',
            type: 'success'
          })
          setTimeout(() => {
            this.closeErrorMessage()
          }, 2000)
          this.isSubmitting = false
          this.cancel()
        }
      }
    }
  }
}
</script>

<style scoped>
  .member-update {

  }
  .member-update__container {

  }
@media only screen and (max-width: 600px) {
  .member-update__container {
    height: auto;
  }
}
</style>
